import React, { FC, useEffect, useState } from "react";
import { Avatar, Button } from "ui-atoms";
import { Modal } from "ui-molecules";
import type { BrokerProps } from "types";
import { Link } from "react-router-dom";
import { getFirstnameFromFullname, noPropagation } from "utils";
import { FiExternalLink } from "react-icons/fi";
import { useApiCall } from "hooks";
import { getBrokerAPI, getBrokersAPI } from "services";

interface BrokerModalProps {
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  broker?: BrokerProps;
  brokerId?: number;
}

const BrokerDetailModal: FC<BrokerModalProps> = ({
  isOpen = false,
  setIsOpen,
  broker,
  brokerId,
}) => {
  const [getBroker] = useApiCall(getBrokerAPI);
  const [brokerData, setBrokerData] = useState<BrokerProps>();

  useEffect(() => {
    if (!broker) return;
    setBrokerData(broker);
  }, [broker]);

  useEffect(() => {
    if (!brokerId) return;
    getBroker(brokerId).then((data: BrokerProps) => {
      setBrokerData(data);
    });
  }, [brokerId]);

  const getRow = (label: string, value: any) => (
    <div className="flex items-center py-4">
      <div className="w-1/2 text-jll-color-coldGray-7">{label}</div>
      <div className="w-1/2 font-semibold text-right text-jll-color-coldGray-8">
        {value}
      </div>
    </div>
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div>
        <div className="flex items-center mb-4 justify-between">
          <div className="flex space-x-2 items-center">
            <Avatar alt="" size="default" src={brokerData?.photo || ""} />
            <div className="font-bold text-jll-color-text-base-default text-lg">
              {brokerData?.name}
            </div>
          </div>
          <Button
            variant="secondary"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </div>
        <div className="flex flex-row justify-end mb-2">
          <Link to={`/properties?broker[]=${brokerData?.pk}`} className="mr-2">
            <Button
              variant="primary"
              size="small"
              className="truncate"
              trailingIcon={FiExternalLink}
            >
              {`View ${getFirstnameFromFullname(
                brokerData?.name || ""
              )}'s Properties`}
            </Button>
          </Link>
          <Link to={`/availabilities?broker[]=${brokerData?.pk}`}>
            <Button
              variant="primary"
              size="small"
              className="truncate"
              trailingIcon={FiExternalLink}
            >
              {`View ${getFirstnameFromFullname(
                brokerData?.name
              )}'s Availability`}
            </Button>
          </Link>
        </div>
        <div className="flex flex-col text-md divide-y">
          {brokerData?.page_url &&
            getRow(
              "Profile",
              <a
                href={broker?.page_url}
                target="_blank"
                className="text-jll-color-icon-info text-ellipsis overflow-hidden"
              >
                View Agent Profile
              </a>
            )}
          {brokerData?.email &&
            getRow(
              "Email",
              <a
                href={`mailto:${brokerData?.email}`}
                className="text-jll-color-surface-info-default"
                onClick={noPropagation}
              >
                {brokerData?.email}
              </a>
            )}
          {brokerData?.telephone &&
            getRow(
              "Telephone",
              <a
                href={`tel:${brokerData?.telephone}`}
                className="text-jll-color-surface-info-default"
                onClick={noPropagation}
              >
                {brokerData?.telephone}
              </a>
            )}
          {brokerData?.language &&
            getRow(
              "Language",
              <div className="font-semibold text-jll-color-coldGray-8">
                {brokerData?.language === "en"
                  ? "English"
                  : brokerData?.language === "fr"
                  ? "French"
                  : brokerData?.language}
              </div>
            )}
          {brokerData?.linkedin &&
            getRow(
              "Linked In",
              <a
                href={broker?.linkedin}
                className="text-jll-color-surface-info-default"
              >
                LinkedIn
              </a>
            )}
          {brokerData?.office && getRow("Office", brokerData?.office)}
          {brokerData?.department &&
            getRow("Department", brokerData?.department)}

          {brokerData?.job_title && getRow("Job title", brokerData?.job_title)}

          {brokerData?.service_line &&
            getRow("Service line", brokerData?.service_line)}
        </div>
      </div>
    </Modal>
  );
};

export default BrokerDetailModal;
