import cn from "classnames";
import { FiChevronLeft, FiExternalLink } from "react-icons/fi";
import { useLocation, Link, useParams } from "react-router-dom";
import { Button, Chip, Switch } from "ui-atoms";
import { useContext } from "react";
import { GlobalContext } from "context";
import LayoutEditPropertySidebar from "./LayoutEditPropertySidebar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { convertExtension, lazyLoadImageOnError } from "utils";
import { convertImageUrl } from "services";
import { URLS } from "constant";
import LayoutEditTeamListingSidebar from "./LayoutEditTeamListingSidebar";

interface menuItem {
  label: string;
  icon: any;
  id?: string;
  count?: boolean;
  suffixUrl: string;
  isOLMToggle?: boolean;
}

interface LayoutEditSidebarProps {
  menuItems: menuItem[];
  backToUrl: string;
  olmToggle?: { isOLM: boolean; setIsOLM: any };
}

function LayoutEditSidebar<T extends object>({
  menuItems,
  backToUrl,
  olmToggle,
}: LayoutEditSidebarProps) {
  const { state } = useContext(GlobalContext);
  const { availability, meta, showTooltip, hideTooltip, property, ...rest } =
    state;
  const { propertyId, availabilityId } = useParams();
  const location = useLocation();
  const isNewAvailability = availabilityId === "new";

  const hasPropertyLink = () => {
    return availability?.building_page_url ? true : false;
  };

  const getPropertyLink = () => {
    let url = `${meta?.PROPERTY_STATIC_URL}${property?.page_url}`;
    if (property?.global_market === "ca" && meta?.PROPERTY_STATIC_URL_CA) {
      if (property?.language === "fr") {
        url = `${meta?.PROPERTY_STATIC_URL_CA}/fr-ca${property?.page_url}`;
      } else {
        url = `${meta?.PROPERTY_STATIC_URL_CA}/en-ca${property?.page_url}`;
      }
    } else if (property?.wls && property?.wls != null) {
      url = `${meta?.PROPERTY_STATIC_URL}/${property?.wls}${property?.page_url}`;
    }
    return url;
  };

  return (
    <div
      className={cn(
        "fixed top-[64px] bottom-0 left-0 w-[360px] pt-8 overflow-y-auto flex flex-col justify-between bg-jll-color-surface-info-subdued"
      )}
    >
      <div>
        <div className="border-b border-black border-opacity-10 px-8 pb-9">
          <div className="flex flex-row justify-between items-center">
            <Link to={backToUrl}>
              <Button
                variant="neutral"
                leadingIconClass="w-5 h-5"
                leadingIcon={FiChevronLeft}
              >
                Back to property
              </Button>
            </Link>
            {hasPropertyLink() && (
              <a
                className="text-jll-color-icon-info"
                href={getPropertyLink()}
                target="_blank"
                rel="noreferrer"
              >
                Property Link <FiExternalLink className="w-5 h-5 inline" />
              </a>
            )}
          </div>
          <div className="flex flex-row pt-9">
            {(availability?.floorplans?.[0] || availability?.images?.[0]) && (
              <LazyLoadImage
                src={convertImageUrl(
                  convertExtension(
                    availability?.floorplans?.[0] || availability?.images?.[0]
                  )
                )}
                className="w-[100px] h-[100px] rounded mr-4"
                onError={lazyLoadImageOnError}
              />
            )}
            <div className="flex flex-col overflow-hidden">
              <p
                className="text-3xl font-semibold text-jll-color-coldGray-9 whitespace-normal line-clamp-2"
                id="suite-name"
                data-tooltip-content={availability?.name}
                onMouseOver={() => showTooltip("suite-name")}
                onMouseLeave={() => hideTooltip()}
              >
                {availability?.name && `Suite ${availability?.name}`}
              </p>
              {availability?.floor && (
                <p className="text-jll-color-text-base-default text-base">
                  Floor {availability?.floor}
                </p>
              )}
            </div>
          </div>
          <div className="mt-8 text-jll-color-coldGray-9">
            <Link
              to={`/property/${propertyId}/${URLS.PROPERTY.FORM}`}
              className="text-jll-color-icon-info"
            >
              {availability?.building_title && (
                <p>{availability?.building_title}</p>
              )}
              {availability?.building_address && (
                <p>{availability?.building_address}</p>
              )}
              {availability?.building_city && (
                <p>
                  {[
                    availability?.building_city,
                    [
                      availability?.building_state,
                      availability?.building_post_code,
                    ].join(" "),
                  ].join(", ")}
                </p>
              )}
            </Link>
          </div>
          <div className="mt-6 space-x-2">
            <Chip variant="primary">
              {availability?.building_property_type}
            </Chip>
          </div>
        </div>

        <div className="mt-8 px-8">
          {!isNewAvailability &&
            menuItems?.map((item: any, idx) => {
              const active = location.pathname.includes(
                item.tag || item.suffixUrl
              );
              const Icon = item.icon;
              let to = `/property/${propertyId}`;

              to += `/availability/${availabilityId}/${item.suffixUrl}`;

              let count = "";
              if (item?.count) {
                if (availability?.[item.id]?.length > 0) {
                  count = availability?.[item.id]?.length;
                }
              }

              return (
                <Link
                  key={idx}
                  className={cn(
                    "flex justify-between items-center cursor-pointer mb-6 hover:text-jll-color-coldGray-11",
                    {
                      "text-jll-color-coldGray-11": active,
                      "text-jll-color-coldGray-4": !active,
                    }
                  )}
                  to={to}
                >
                  <div className="flex flex-row items-center">
                    <Icon className="w-5 h-5 mr-2" />
                    <span className={cn({ "font-semibold": active })}>
                      {item.label}
                    </span>
                  </div>
                  {count && (
                    <span className={cn({ "font-semibold": active })}>
                      {count}
                    </span>
                  )}
                  {item?.isOLMToggle && (
                    <Switch
                      enabled={olmToggle?.isOLM || false}
                      onChange={(flag: boolean) => olmToggle?.setIsOLM(flag)}
                      label_1="All"
                      label_2="Only OLM"
                      className="!mb-0"
                    />
                  )}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}

LayoutEditSidebar.Property = LayoutEditPropertySidebar;
LayoutEditSidebar.TeamListing = LayoutEditTeamListingSidebar;

export default LayoutEditSidebar;
